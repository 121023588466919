import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { createfollowingChat } from '../../api/service';
import './popupWelcome.css';
import '../../App.css'

export default function ResponsiveDialog({data, handleChangeAdvisor, handleReady}) {
  const [open, setOpen] = useState(true);
  const [time1, setTime1] = useState(false);
  const [time2, setTime2] = useState(false);
  const [time3, setTime3] = useState(false);
  const [time4, setTime4] = useState(false);
  const [sound, setSound] = useState(0);
  const [advisor, setAdvisor] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    setTimeout(() => setTime1(true), 2000);
    setTimeout(() => setTime2(true), 4000);
  }, [])

    const selectAdvisor = async(ad) => {
      setAdvisor(ad);
      localStorage.setItem('advisor', ad);
      let res = await createfollowingChat({ galien_id: localStorage.getItem("organizationId"), chat_id: null, advisor });
      localStorage.setItem('followingCactusId', res.id);
      setTimeout(() => {setTime3(true)}, 3000);
      setTimeout(() => {setTime4(true)}, 4000);
  }

  const setSoundAction = (action) => {
    setSound(action);

    if(action === 1){
      localStorage.setItem('mute', 'false');
    }
    else{
      localStorage.setItem('mute', 'true');
    }
    handleClose()
    handleReady(true)
    
  }

  const handleClose = () => {
    setTimeout(() => {
      handleChangeAdvisor(advisor);
      setOpen(false);
    }, 2000);
  };

  return (
    <React.Fragment>
      <Dialog
  fullScreen={fullScreen}
  open={open}
  onClose={handleClose}
  aria-labelledby="responsive-dialog-title"
  PaperProps={{
    sx: {
      background: "url('/assets/images/background/backgroundConfidence.gif')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      fontFamily: 'customFont'
    },
  }}
>
        <DialogContent>
          {!time3 && <Box sx={{width: 320, height: 250, margin: 'auto', position: 'absolute', top: (advisor ? '-250px' : 0), bottom: 0, left: 0, right: 0}}>
            <div className={'txt'} style={{animation: 'fadeIn 3s', fontSize: (time1 ? '21px' : '41px'), lineHeight: '54px', position: (time1 ? 'fixed' : 'relative'), opacity: (time1 ? '0.6' : '1')}}>Bienvenue chez, <b>Confidences</b></div>
            <div className={'txt'} style={{fontSize: (advisor ? '21px' : '31px'), lineHeight: (advisor ? '24px' : '42px'), opacity: (time1 && !advisor ? '1' : (advisor ? '0.6' : 0)), position: (time2 ? 'initial' : 'relative')}}>Avant de commencer, sélectionnez votre coiffeur.</div>
            <div className={'coiffeurs'} style={{opacity: (time2 ? '1' : '0'), height: (time2 ? '70px' : '0')}}>
              {data && data.map((ad, index) => (
                  <div key={index} className={'coiffeur'} style={{opacity: (!advisor || advisor === ad.hairDresser_name ? '1' : '0.25')}} onClick={() => {
                    selectAdvisor(ad.hairDresser_name)
                  }}>
                   <span>{ad.hairDresser_name}</span>
                  </div>
              ))}
            </div>
            {advisor && <div className={'txt'} style={{marginTop: '62px', fontSize: '26px'}}>Vous avez sélectionné, <br/><b>{advisor}</b></div>}
          </Box>}
          {time3 && <Box sx={{width: 320, height: 250, margin: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
            <div className={'txt'} style={{fontSize: '26px'}}>Avant de commencer acceptez-vous d’activer le son ?</div>
            {time4 && <div className={'chois-btns'}>
              <button onClick={() => setSoundAction(1)} style={{opacity: ((!sound || sound === 1) ? '1' : '0.6')}}>Oui</button>
              <button onClick={() => setSoundAction(2)} style={{opacity: ((!sound || sound === 2) ? '1' : '0.4')}}>Non</button>
            </div>}
          </Box>}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

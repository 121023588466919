import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import './videoIntro.css'
import {Langage} from "../../language";
import {appLanguage, titleNormalized} from "../../select";
import {Box} from "@mui/material";
import { handleVideo } from '../../utils/utils';

const iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

let showDisableLecture = false;
const getText = (key) => {
    return Langage[key][appLanguage];
}

const muteTextToSpeech = () => {
    localStorage.setItem('MUTE_TTS', 'true');
}

const activateTextToSpeech = () => {
    localStorage.setItem('MUTE_TTS', 'false');
}

const VideoIntro = ({handleCloseVid, handleReady}) => {

  const [showLoading, setShowLoading] = useState(true);


  useEffect(() => {
    setShowLoading(true);
    handleVideo(localStorage.getItem('video_intro') || `/assets/videos/intro-${titleNormalized}-${appLanguage}.mp4`, (localStorage.getItem('video_text') || getText('start')), (+localStorage.getItem('ai_video_duration') || 10200), localStorage.getItem('mute'));
    goToNext();
  }, []);

  const goToNext = () => {
    // document.getElementById('video_intro').disabled = true;
    setTimeout(async () => {
      handleCloseVid(true);
      setShowLoading(false);
      handleReady(true)
    }, (localStorage.getItem('ai_video_duration') || 200))
  }
    return (
        <div>
            {/*<Button  onClick={()=> {handleVideo(localStorage.getItem('video_intro') || `/assets/videos/intro-${titleNormalized}-${appLanguage}.mp4`, (localStorage.getItem('video_text') || getText('start')), (+localStorage.getItem('ai_video_duration') || 10200)); goToNext();  }}*/}
            {/*        variant="contained" className={'btn-black'} id={'video_intro'} endIcon={<DoneIcon />}>*/}
            {/*    {getText('i_accept')}*/}
            {/*</Button>*/}
            
            <div id="container_vid">
                <video playsInline id="vid">
                    <source type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className={'vid-text-talk'}>
                    <img src="/assets/images/ai_talking.png" alt=""/>
                    <div id="vid-text">
                        <span id="vid_text_span"></span>
                    </div>
                </div>
              {showLoading && <span className={'loading-points'} style={{bottom: '20px'}}><span></span></span>}
            </div>

            {/* <Box>
                {
                    showDisableLecture && <Button variant="contained" className={'btn-black'} onClick={()=> { muteTextToSpeech() }}>
                        {getText('disable_lecture')}
                    </Button>
                }
            </Box> */}
        </div>
    )
}

export default VideoIntro

export const hairPropertiesJson = {
    "genre": [
        {
            "btn": "femme",
            "text": "je suis une femme"
        },
        {
            "btn": "homme",
            "text": "je suis un homme"
        },
        {
            "btn": "non binaire",
            "text": "je suis non binaire"
        }
    ],
    "longueur": [
        {
            "btn": "courts",
            "text": "mes cheveux sont courts"
        },
        {
            "btn": "mi-longs",
            "text": "mes cheveux sont mi-longs"
        },
        {
            "btn": "longs",
            "text": "mes cheveux sont longs"
        },
        {
            "btn": "très_long",
            "text": "mes cheveux sont très long"
        }
    ],
    "type": [
        {
            "btn": "raides",
            "text": "mes cheveux sont raides"
        },
        {
            "btn": "ondulés",
            "text": "mes cheveux sont ondulés"
        },
        {
            "btn": "bouclés",
            "text": "mes cheveux sont bouclés"
        },
        {
            "btn": "frisés",
            "text": "mes cheveux sont frisés"
        },
        {
            "btn": "crépus",
            "text": "mes cheveux sont crépus"
        }
    ],
    "epaisseur": [
        {
            "btn": "fins",
            "text": "mes cheveux sont fins"
        },
        {
            "btn": "normaux",
            "text": "mes cheveux sont normaux"
        },
        {
            "btn": "épais",
            "text": "mes cheveux sont épais"
        }
    ],
    "couleur": [
        {
            "btn": "blonds",
            "text": "mes cheveux sont blonds"
        },
        {
            "btn": "chatains",
            "text": "mes cheveux sont chatains"
        },
        {
            "btn": "bruns",
            "text": "mes cheveux sont bruns"
        },
        {
            "btn": "roux",
            "text": "mes cheveux sont roux"
        },
        {
            "btn": "blancs",
            "text": "mes cheveux sont blancs"
        }
    ],
    "qualite": [
        {
            "btn": "parfaits",
            "text": "mes cheveux sont parfaits"
        },
        {
            "btn": "fragiles",
            "text": "mes cheveux sont fragiles"
        },
        {
            "btn": "cassants",
            "text": "mes cheveux sont cassants"
        },
        {
            "btn": "abimés",
            "text": "mes cheveux sont abimés"
        },
        {
            "btn": "fourchus",
            "text": "mes cheveux sont fourchus"
        },
        {
            "btn": "déshydratés",
            "text": "mes cheveux sont déshydratés"
        }
    ],
    "etat": [
        {
            "btn": "naturels",
            "text": "mes cheveux sont naturels"
        },
        {
            "btn": "colorés",
            "text": "mes cheveux sont colorés"
        },
        {
            "btn": "méchés",
            "text": "mes cheveux sont méchés"
        },
        {
            "btn": "permanentés",
            "text": "mes cheveux sont permanentés"
        },
        {
            "btn": "lissés",
            "text": "mes cheveux sont lissés"
        }
    ],
    "cuir chevelu type": [
        {
            "btn": "mixte",
            "text": "mon cuir chevelu est de type mixte"
        },
        {
            "btn": "gras",
            "text": "mon cuir chevelu est gras"
        },
        {
            "btn": "sec",
            "text": "mon cuir chevelu est sec"
        },
        {
            "btn": "équilibré",
            "text": "mon cuir chevelu est équilibré"
        }
    ],
    "cuir chevelu etat": [
        {
            "btn": "normal",
            "text": "Mon cuir chevelu n'est pas sensible"
        },
        {
            "btn": "irrité",
            "text": "mon cuir chevelu est irrité"
        },
        {
            "btn": "sensible",
            "text": "mon cuir chevelu est sensible"
        }
    ],
    "objectifs soin": [
        {
            "btn": "hydrater",
            "text": "mon objectif soin est d' hydrater"
        },
        {
            "btn": "nourrir",
            "text": "mon objectif soin est de nourrir"
        },
        {
            "btn": "apaiser",
            "text": "mon objectif soin est d' apaiser"
        },
        {
            "btn": "réparer",
            "text": "mon objectif soin est de réparer"
        },
        {
            "btn": "fortifier",
            "text": "mon objectif soin est de fortifier"
        },
        {
            "btn": "stimuler croissance",
            "text": "mon objectif soin est de stimuler croissance"
        },
        {
            "btn": "réguler sébum",
            "text": "mon objectif soin est de réguler sébum"
        }
    ],
    "objectifs beaute": [
        {
            "btn": "lissage",
            "text": "mon objectif beauté est le lissage"
        },
        {
            "btn": "brillance",
            "text": "mon objectif beauté est la brillance"
        },
        {
            "btn": "volume",
            "text": "mon objectif beauté est le volume"
        },
        {
            "btn": "boucles",
            "text": "mon objectif beauté est les boucles"
        },
        {
            "btn": "protéger coloration",
            "text": "mon objectif beauté est de protéger la coloration"
        }
    ],
    "pellicules": [
        {
            "btn": 'jamais',
            "text": "je n'ai pas de pellicules."
        },
        {
            "btn": 'parfois',
            "text": "j'ai parfois des pellicules."
        },
        {
            "btn": "souvent",
            "text": "j'ai des pellicules souvent ."
        }
    ],
    "chute cheveux": [
        {
            "btn": "aucune",
            "text": "je ne suis pas sujet à la chute de cheveux."
        },
        {
            "btn": "normale",
            "text": "j'ai une chute de cheveux rare."
        },
        {
            "btn": "excessive",
            "text": "j'ai une chute de cheveux importante."
        }
    ]
}
